<template>
  <v-card :loading="isLoading">
    <div class="card card-custom gutter-b example example-compact">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="originalData.name">
            <small>Gelir Türü</small><br />
            <span>{{ originalData.name }}</span>
          </div>
          <span v-else>Gelir Türü</span>
          <div>
            <small>{{ cluster.name }} Gelir Türü</small>
          </div>
        </h3>
        <v-col class="text-right p-0">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col sm="12">
              <rs-text-field
                label="Adı"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
                :readonly="disabled || (id && !originalData.cluster_id)"
                :filled="disabled || (id && !originalData.cluster_id)"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            @submit.prevent="handleFormSubmit"
          />
        </v-form>
        <ActionLogList ref="actionLogList" />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import hasForm from "@/view/mixins/hasForm";
import ClusterService from "@/core/services/cluster.service";

export default {
  mixins: [hasForm],
  components: {
    ActionLogList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "cluster"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        cluster_id: null,
        name: null,
        is_active: true,
      },
      originalData: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`income-types/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (
            this.originalData.cluster_id &&
            this.clusterId !== this.originalData.cluster_id
          ) {
            this.updateClusterId(this.originalData.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`income-types/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadIncomeTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/income-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadIncomeTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.income-types.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Type\\IncomeType", this.id);
    },
  },
};
</script>
